/* atoms/meter.scss */

meter {
	height: 4px;
	width: 100%;
	//firefox box
	background: none; //gets rid of firefox default
	background-color: $shade-light;
	border-radius: 4px;
}

//chrome box
meter::-webkit-meter-bar {
	background: none; //gets rid of default
	background-color: $shade-light;
	border-radius: 4px;
	//edge chromium
	border: none;
	height: 100%;
}
//chrome bar
meter::-webkit-meter-optimum-value {
	background: none; //gets rid of default
	background-color: $light-green;
	border-radius: 4px;
}

//firefox bar
meter:-moz-meter-optimum::-moz-meter-bar {
  background: $light-green;
	border-radius: 4px;
}

//meter labels
.meter-label {
	color: white;
	display: inline-block;
	background-color: $light-green;
	position: absolute;
	bottom: 0;
	font-size: 1rem;
	line-height: 1rem;
	padding: 2px 3px 2px 4px;
	border-radius: 2px;
}
.meter-label:after {
	bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $light-green;
  border-width: 5px;
  margin-left: -5px;
}