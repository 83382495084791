/* organisms/img-l-50.scss */

div.img-l-50 {
	padding-left: 50%;
	background-size: 50%;
	background-position: top left;
	background-repeat: no-repeat;
	> * {
		margin-left: 96px;
	}
}

div#me {
	background-image: url('img/bryan.jpg');
	min-height: 445px;
}

@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {

	div#me {
		background-image: url('img/bryan@2x.jpg');
	}
}


@media
only screen and (-webkit-min-device-pixel-ratio : 2.5),
only screen and (min-device-pixel-ratio : 2.5) {
	
	div#me {
		background-image: url('img/bryan@3x.jpg');
	}
}

@media (max-width: 868px) {
	div.img-l-50#me {
		background-position: top center;
		background-size: 50vw;
		padding-left: 0;
		padding-top: 56vw;
		> * {
			margin-left: 0;
		}
		> *:first-child {
			margin-top: $space-large;
		}
	}
}