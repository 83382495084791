/* atoms/address.scss */

address {
	font-style: normal;
	h4 {
		font-size: 1.2rem;
		font-spacing: .1em;
		font-weight: $font-heavyweight;
		margin-bottom: 5px;
		text-transform: uppercase;
	}
	p	{
		font-size: 1.6rem;
		margin-top: 0;
		margin-bottom: 20px;
	}
}