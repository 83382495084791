/* atoms/button.scss */

button, a.button {
	color: $white;
	background-color: $light-green;
	border-style: solid;
	border-width: 1px;
	border-color: $light-green;
	border-radius: 100px;
	display: inline-block;
	font-family: $font-stack;
	font-size: 1.4rem;
	font-weight: $font-mediumweight;
	padding: 1.6rem 2.4rem;
	text-transform: uppercase;
	margin-right: 1rem;
	margin-bottom: 16px;
}

button:hover, a.button:hover {
	border-color: $medium-green;
	background-color: $medium-green;
}

button.secondary, a.button.secondary {
	border-color: $shade-medium-dark;
	background-color: $shade-medium-dark;
}

button:visited, a.button:visited {
	color: $white;
	text-decoration: none;
}

button.nav {
	border: none;
	position: absolute;
	top: $space-small;
	left: $space-large;
	display: none;
	padding: 0;
	margin: 0;
	background-color: transparent;
}

button.nav:hover {
	background-color: transparent;
}