/* atoms/typography.scss */

h1, h2, h3, h4, h5, h6 {
	margin-top: 0.8rem;
	margin-bottom: 0.8rem;
}

h1, .h1 {
	font-family: $font-stack;
	color: $white;
	font-size: 7rem;
	font-weight: $font-featherweight;
	line-height: 8.4rem;
}

h2, .h2 {
	color: $color-medium;
	font-family: $font-stack;
	font-size: 3rem;
	font-weight: $font-featherweight;
	line-height: 3.6rem;
}

h3, .h3 {
	color: $color-medium;
	font-family: $font-stack;
	font-size: 2.8rem;
	font-weight: $font-featherweight;
	line-height: 3.4rem;
}

h4, .h4 {
	color: $color-medium;
	font-family: $font-stack;
	font-size: 2.5rem;
	font-weight: $font-featherweight;
	line-height: 2.9rem;
}

h5, .h5 {
	color: $color-medium;
	font-family: $font-stack;
	font-size: 2rem;
	font-weight: $font-featherweight;
	line-height: 2.4rem;
}

h6, .h6 {
	color: $color-light;
	font-family: $font-stack;
	font-size: 1.6rem;
	font-weight: $font-heavyweight;
	line-height: 1.9rem;
}

h1:first-child, .h1:first-child,
h2:first-child, .h2:first-child,
h3:first-child, .h2:first-child,
h4:first-child, .h4:first-child,
h5:first-child, .h5:first-child,
h6:first-child, .h6:first-child,
p:first-child {
	margin-top: 0;
}

h1:last-child, .h1:last-child,
h2:last-child, .h2:last-child,
h3:last-child, .h2:last-child,
h4:last-child, .h4:last-child,
h5:last-child, .h5:last-child,
h6:last-child, .h6:last-child,
p:last-child {
	margin-bottom: 0;
}

$line-width: 50px;

.lined {
	padding-top: 20px;
	position: relative;
}

.lined:before {
	content: " ";
	position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: $line-width;
  background: $light-green;
}

.center.lined:before {
	left: calc(50% - (#{$line-width}/2);
}

p, li {
	font-family: $font-stack;
	color: $color-light;
	font-weight: $font-welterweight;
	font-size: 1.6rem;
	line-height: 2.7rem;
}

b, strong{
	font-weight: $font-superheavyweight;
}

a {
	color: $light-green;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

a:visited {
	color: $light-green;
}

small {
	color: $color-light;
	display: block;
	font-family: $font-stack;
	font-size: 1.6rem;
	margin-top: 16px;
}