/* molecules/navs.scss */

nav.site {
	padding-top: 23px;
	padding-bottom: 23px;
	ul {
		list-style: none;
		text-align: center;
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			padding-left: 20px;
			padding-right: 20px;
		}
		li:first-child {
			padding-left: 0;
		}
		li:last-child {
			padding-right: 0;
		}
	}
	a {
		color: $white;
		text-transform: uppercase;
	}
}

html.js header.site nav.site.js-open {
	display: block;
	ul {
		padding-left: 20px;
		text-align: left;
		li {
			display: block;
			padding-left: 0;
			padding-right: 0;
			margin-top: 30px;
			margin-bottom: 30px;
		}
		li:first-child {
			margin-top: 0;
		}
		li:last-child {
			margin-bottom: 0;
		}
	}
}

nav.body {
	ul {
		list-style-type: none;
		padding-left: 0;
		li {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			margin-left: 10px;
			margin-right: 10px;
		}
		li:first-child {
			margin-left: 0;
		}
		li:last-child {
			margin-right: 0;
		}
		li.active, li:hover {
			color: $light-green;
		}
	}
}