/* atoms/inputs.scss */

input, textarea {
	border: none;
	color: $shade-dark;
	font-family: $font-stack;
	font-size: 1.6rem;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	display: block;
	margin-top: 8px;
	margin-bottom: 8px;
	padding: 13px;
	width: 100%;
}

input::placeholder, textarea::placeholder {
	color: $shade-medium-light;
	font-family: $font-stack;
	font-size: 1.6rem;
}

input:first-child, textarea:first-child {
	margin-top: 0px;
}

input:last-child, textarea:last-child {
	margin-bottom: 0px;
}

label {
	color: $shade-medium-light;
	display: block;
	font-family: $font-stack;
	font-size: 1.6rem;
	margin-top: 16px;
}

label:first-child {
	margin-top: 0;
}

button[type=submit]{
	margin-top: 48px;
}