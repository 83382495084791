/* organisms/sections.scss */

section {
	margin-top: $space-large;
	margin-bottom: $space-large;
	padding: $space-large;
}

section:last-child {
	padding-bottom: 0;
}

section.hero {
	background-color: #cccccc;
	background-repeat: no-repeat;
	background-attachment: fixed;
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 0;
	height: 100vh;
	padding-top: $space-large;
	padding-right: $space-large;
	padding-bottom: $space-large;
	text-align: center;
	div.wrapper {
		z-index: 2;
	}
	*:not(a) {
		color: $white;
	}
}

section.hero:before { 
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); 
}
	
section#intro {
	background-size: cover;
}


//file sizes
$size1: 768px;
$size2: 1024px;
$size3: 1444px;
$size4: 1824px;
$size5: 2736px;
$size6: 3648px;
$size7: 4561px;
$size8: 5473px;

//768 file
@media 	
	/*1x*/(max-width: $size1),
	/*2x*/(max-width: $size1/2) and (min-resolution: 1.1dppx) and (max-resolution: 2dppx),
	/*3x*/(max-width: $size1/3) and (min-resolution: 2.1dppx) and (max-resolution: 3dppx),
	/*4x*/(max-width: $size1/4) and (min-resolution: 4.1dppx) and (max-resolution: 4dppx),
	/*5x*/(max-width: $size1/5) and (min-resolution: 5.1dppx) and (max-resolution: 5dppx) {

	section#intro {
		background-image: url('img/hero/desktop-768.jpg');
	}
}

//1024 file
@media 	
	/*1x*/(min-width: $size1   +1) and (max-width: $size2),
	/*2x*/(min-width: $size1/2 +1) and (max-width: $size2/2) and (min-resolution: 1.1dppx) and (max-resolution: 2dppx),
	/*3x*/(min-width: $size1/3 +1) and (max-width: $size2/3) and (min-resolution: 2.1dppx) and (max-resolution: 3dppx),
	/*4x*/(min-width: $size1/4 +1) and (max-width: $size2/4) and (min-resolution: 3.1dppx) and (max-resolution: 4dppx),
	/*5x*/(min-width: $size1/5 +1) and (max-width: $size2/5) and (min-resolution: 4.1dppx) and (max-resolution: 5dppx) {

	section#intro {
		background-image: url('img/hero/desktop-1024.jpg');
	}
}

//1444 file
@media 	
	/*1x*/(min-width: $size2   +1) and (max-width: $size3),
	/*2x*/(min-width: $size2/2 +1) and (max-width: $size3/2) and (min-resolution: 1.1dppx) and (max-resolution: 2dppx),
	/*3x*/(min-width: $size2/3 +1) and (max-width: $size3/3) and (min-resolution: 2.1dppx) and (max-resolution: 3dppx),
	/*4x*/(min-width: $size2/4 +1) and (max-width: $size3/4) and (min-resolution: 3.1dppx) and (max-resolution: 4dppx),
	/*5x*/(min-width: $size2/5 +1) and (max-width: $size3/5) and (min-resolution: 4.1dppx) and (max-resolution: 5dppx) {

	section#intro {
		background-image: url('img/hero/desktop-1444.jpg');
	}
}

//1824 file
@media 	
	/*1x*/(min-width: $size3   +1) and (max-width: $size4),
	/*2x*/(min-width: $size3/2 +1) and (max-width: $size4/2) and (min-resolution: 1.1dppx) and (max-resolution: 2dppx),
	/*3x*/(min-width: $size3/3 +1) and (max-width: $size4/3) and (min-resolution: 2.1dppx) and (max-resolution: 3dppx),
	/*4x*/(min-width: $size3/4 +1) and (max-width: $size4/4) and (min-resolution: 3.1dppx) and (max-resolution: 4dppx),
	/*5x*/(min-width: $size3/5 +1) and (max-width: $size4/5) and (min-resolution: 4.1dppx) and (max-resolution: 5dppx) {

	section#intro {
		background-image: url('img/hero/desktop-1824.jpg');
	}
}

//2736 file
@media 	
	/*1x*/(min-width: $size4   +1) and (max-width: $size5),
	/*2x*/(min-width: $size4/2 +1) and (max-width: $size5/2) and (min-resolution: 1.1dppx) and (max-resolution: 2dppx),
	/*3x*/(min-width: $size4/3 +1) and (max-width: $size5/3) and (min-resolution: 2.1dppx) and (max-resolution: 3dppx),
	/*4x*/(min-width: $size4/4 +1) and (max-width: $size5/4) and (min-resolution: 3.1dppx) and (max-resolution: 4dppx),
	/*5x*/(min-width: $size4/5 +1) and (max-width: $size5/5) and (min-resolution: 4.1dppx) and (max-resolution: 5dppx) {

	section#intro {
		background-image: url('img/hero/desktop-2736.jpg');
	}
}

//3648 file
@media 	
	/*1x*/(min-width: $size5   +1) and (max-width: $size6),
	/*2x*/(min-width: $size5/2 +1) and (max-width: $size6/2) and (min-resolution: 1.1dppx) and (max-resolution: 2dppx),
	/*3x*/(min-width: $size5/3 +1) and (max-width: $size6/3) and (min-resolution: 2.1dppx) and (max-resolution: 3dppx),
	/*4x*/(min-width: $size5/4 +1) and (max-width: $size6/4) and (min-resolution: 3.1dppx) and (max-resolution: 4dppx),
	/*5x*/(min-width: $size5/5 +1) and (max-width: $size6/5) and (min-resolution: 4.1dppx) and (max-resolution: 5dppx) {

	section#intro {
		background-image: url('img/hero/desktop-3648.jpg');
	}
}

//4561 file
@media 	
	/*1x*/(min-width: $size6   +1) and (max-width: $size7),
	/*2x*/(min-width: $size6/2 +1) and (max-width: $size7/2) and (min-resolution: 1.1dppx) and (max-resolution: 2dppx),
	/*3x*/(min-width: $size6/3 +1) and (max-width: $size7/3) and (min-resolution: 2.1dppx) and (max-resolution: 3dppx),
	/*4x*/(min-width: $size6/4 +1) and (max-width: $size7/4) and (min-resolution: 3.1dppx) and (max-resolution: 4dppx),
	/*5x*/(min-width: $size6/5 +1) and (max-width: $size7/5) and (min-resolution: 4.1dppx) and (max-resolution: 5dppx) {

	section#intro {
		background-image: url('img/hero/desktop-4561.jpg');
	}
}

//5473 file
@media 	
	/*1x*/(min-width: $size7   +1),
	/*2x*/(min-width: $size7/2 +1) and (min-resolution: 1.1dppx) and (max-resolution: 2dppx),
	/*3x*/(min-width: $size7/3 +1) and (min-resolution: 2.1dppx) and (max-resolution: 3dppx),
	/*4x*/(min-width: $size7/4 +1) and (min-resolution: 3.1dppx) and (max-resolution: 4dppx),
	/*5x*/(min-width: $size7/5 +1) and (min-resolution: 4.1dppx) and (max-resolution: 5dppx) {

	section#intro {
		background-image: url('img/hero/desktop-5473.jpg');
	}
}

section#skills {
	display: flex;
	> *:first-child {
		flex: 0 0 25%;
		max-width: 25%;
	}
	> ul {
		flex: 0 0 75%;
		max-width: 75%;
	}
}

@media (max-width: $break640) {
	section#skills {
		display: block;
		> *:first-child {
			width: 100%;
			max-width: none;
		}
		ul {
			margin-top: 48px;
			padding-left: 0;
		}
	}
}

section#contact {
	padding-top: 80px;
	padding-bottom: 80px;
	.columns {
		> div {
			flex-grow: 0;
			margin-left: 30px;
			margin-right: 30px; 
		}
		> div:first-child {
			flex-basis: 66%;
			margin-left: 0px;
		}
		> div:last-child {
			flex-basis: 33%;
			margin-right: 0px;
		}
	}
}

section#testimonials, section#resume, section#contact {
	background-color: $shade-light;
}