/* organisms/headers.scss */

header.site, html.js header.site.js-open {
	background-color: $white;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.09);
	nav.site a {
		color: $black;
	}
	nav.site a:hover {
		color: $light-green;
	}
	nav.site a:focus {
		outline: 1px solid $light-green;
		outline-offset: 5px;
	}
}

html.js header.site {
	background-color: transparent;
	box-shadow: none;
	min-height: 73px;
	position: absolute;
	top: 20px;
	width: 100%;
	z-index: 2;
	nav.site a {
		color: white;
	}
}

html.js header.site.js-open {
	position: fixed;
	top: 0;
}

@media (max-width: $break760) {
	
	html.js header.site {
		top: 0;
		background-color: $white;
		nav.site {
			display: none;
			a {
				color: $black;
			}
		}
		nav.site.open {
			display: block;
		}
	}
	html.js button.nav {
		display: block;
		position: relative;
	}

}