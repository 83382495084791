/* atoms/icomoon.scss */

// @font-face {
//   font-family: 'icomoon';
//   src:  url('fonts/icomoon.eot?1z9v6x');
//   src:  url('fonts/icomoon.eot?1z9v6x#iefix') format('embedded-opentype'),
//     		url('fonts/icomoon.ttf?1z9v6x') format('truetype'),
//     		url('fonts/icomoon.woff?1z9v6x') format('woff'),
//     		url('fonts/icomoon.svg?1z9v6x#icomoon') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

.icon {
  background-color: $light-green;
	background-repeat: no-repeat;
  border-radius: 50%;
  color: black;
  display: inline-block;
  height: 100px;
  width: 100px;
  margin-bottom: 30px;
}

.smartphone {
	background-image: url('img/icons/smartphone.svg');
	background-position: 38px 29px;
}

.training {
	background-image: url('img/icons/training.svg');
	background-position: 23px 24px;
}

.design {
	background-image: url('img/icons/design.svg');
	background-position: 30px 30px;
}

.update {
	background-image: url('img/icons/content.svg');
	background-position: 30px 30px;
}

.cms {
	background-image: url('img/icons/cms.svg');
	background-position: 35px 30px;
}

.support {
	background-image: url('img/icons/support.svg');
	background-position: 29px 25px;
}

.consult {
	background-image: url('img/icons/consult.svg');
	background-position: 35px 31px;
}

.newsite {
	background-image: url('img/icons/newsite.svg');
	background-position: 30px 30px;
}