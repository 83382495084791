/* molecules/lists.scss */

ul.mtrs {
	list-style-type: none;
	li {
		position: relative;
	}
	h4 {
		font-size: 1.8rem;
		font-weight: $font-featherweight;
		margin-bottom: 0.8rem;
	}
	meter {
		margin-bottom: 26px;
		width: 100%;
	}
}

ul.quotes {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	list-style-type: none;
	padding-left: 0;
	> li {
		display: flex;
		flex: 1 1 50%;
		margin: 24px 0;
		padding-right: 15px;
		padding-left: 15px;
		img {
			height: 60px;
			width: 60px;
			border-radius: 50%;
			border: 5px solid white;
		}
	}
	> li:nth-child(odd){
		padding-left: 0;
	}
	>li:nth-child(even){
		padding-right: 0;
	}
	blockquote {
		background-color: white;
		box-shadow: 0 2px 30px -2px rgba(0, 0, 0, 0.1);
		margin: 0 0 0 40px;
		padding: 30px;
		position: relative;
	}
	blockquote::before {
		content: "";
		border: 1px solid red;
		z-index: 2;
		position: absolute;
		left: -15px;
		border-width: 0 20px 20px 0;
		border-style: solid;
		border-color: transparent white transparent transparent;
	}
}

ul.icon-tiles {
	display: grid;
	grid-auto-columns: 30%;
	grid-column-gap: 30px;
	grid-row-gap: 48px;
	justify-content: center;
	list-style-type: none;
	padding-left: 0;
	li {
		text-align: center;
	}
	li:nth-child(-n+3) {
		grid-row: 1;
	}
	li:nth-child(n+4):nth-child(-n+3) {
		grid-row: 2;
	}
	li:nth-child(n+7):nth-child(-n+6) {
		grid-row: 3;
	}
}

@media (max-width: $break760) {
	ul.icon-tiles {
		display: block;
		li {
			margin-top: 48px;
			margin-bottom: 48px;
		}
		li:first-child {
			margin-top: 0;
		}
		li:last-child {
			margin-bottom: 0;
		}
	}
}

ul.mac-list {
	list-style-type: none;
	padding-left: 0;
	> li {
		margin-top: 80px;
		margin-bottom: 80px;
		display: flex;
		align-content: flex-start;
		align-items: flex-start;
		> div {
			margin-left: 15px;
			margin-right: 15px;
		}
		> div:first-child {
			margin-left: 0;
		}
		> div:last-child {
			margin-right: 0;
		}
		div.macbook {
			flex-basis: 60%;
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-image: url('img/mac.png');
			position: relative;
			padding-top: 37.25%;
			div.mac-thumb {
				position: absolute;
				left: 12.5%;
				top: 5.75%;
				width: 74.5%;
				height: 74%;
				overflow: scroll;
				img {
					width: 100%;
				}
			}
		}
		div.content {
			flex-basis: 30%;
		}
	}
	> li:first-child {
		margin-top: 0;
	}
	> li:last-child {
		margin-bottom: 0;
	}
}

@media (max-width: $break760) {
	
	ul.mac-list { 

		> li {
			flex-direction: column;
			padding-top: 48px;
			padding-bottom: 48px;
			margin-top: 48px;
			margin-bottom: 48px;

			div.macbook {
				padding-top: 57.25%;
				margin-bottom: 48px;
				width: 100%;

				div.mac-thumb {
					height: 82%;
					top: 7%;
				}
			}
		}

		> li:first-child {
				margin-top: 0;
		}

		> li:last-child {
				margin-bottom: 0;
		}

		> li:nth-child(even) {
			background-color: $shade-light;
				div.content {
					order: 2;
				}
		}
	}
}

ul.cards {
	list-style-type: none;
	padding-left: 0;
	> li {
		background-color: white;
		padding: 40px;
		margin-top: 24px;
		margin-bottom: 24px;
	}
	> li:first-child {
		margin-top: 0;
	}
	> li:last-child {
		margin-bottom: 0;
	}
}

ul.threeAcross {
	list-style-type: none;
	padding-left: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	> li {
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: top center;
		border: 4px solid transparent;
		flex-basis: 29%;
		margin: 30px 15px;
		padding: 22% 30px 30px 30px;
		a:hover {
			text-decoration: none;
		}
	}
	> li:hover {
		border: 4px solid $light-green;
	}
	li > *:first-child {
		margin-top: 30px;
	}
	li:nth-child(3n - 2){
		margin-left: 0;
	}
	li:nth-child(3n){
		margin-right: 0;
	}
}

ul.social {
	list-style-type: none;
	padding-left: 0;
	li {
		display: inline-block;
		border-radius: 50%;
		border: 1px solid $shade-light2;
		height: 60px;
		width: 60px;
		margin-left: 10px;
		margin-right: 10px;
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
			img {
				fill: $light-green;
				height: 22px;
				width: 22px;
			}
		}
	}
	li:first-child {
		margin-left: 0;
	}
	li:last-child {
		margin-right: 0;
	}
}