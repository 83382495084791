/* organisms/wrapper.scss */

.wrapper {
	width: 100%;
	max-width: 1140px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}

.pb-0 {
	padding-bottom: 0;
}

.pt-0 {
	padding-top: 0;
}