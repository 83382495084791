/* molecules/news-images.scss */

#news1 {
	background-image: url('img/news-thumbs/lake.jpg');
}
#news2 {
	background-image: url('img/news-thumbs/shake.jpg');
}
#news3 {
	background-image: url('img/news-thumbs/beats.jpg');
}