/* organisms/utility.scss */

.center {
	text-align: center;
}

h1.center, h2.center, h3.center, h4.center, h5.center, h6.center {
	margin-bottom: 80px;
}

.columns {
	display: flex;
	> div {
		flex-basis: 50%;
		flex-grow: 1;
		margin-left: 15px;
		margin-right: 15px;
	}
	> div:first-child {
		margin-left: 0;
	}
	> div:last-child {
		margin-right: 0;
	}
}