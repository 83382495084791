$shade-light: #f8f9fa;
$shade-light2: #b3bdc6;
$shade-medium-light: #6c757d;
$shade-medium-dark: #5a6268;
$shade-dark: #495057;
$color-light: #798a9a;
$color-medium: #2c3e50;
$color-dark: #2c3e50;
$light-green: #57d131;
$medium-green: #4ebe2a;

$black: #000000;
$white: #ffffff;

$space-large: 40px;
$space-small: 10px;

$font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-featherweight: 200;
$font-welterweight: 300;
$font-mediumweight: 400;
$font-heavyweight: 500;
$font-superheavyweight: 800;

//breakpoints
$break760: 760px;
$break640: 640px;